import React from 'react';

const NotFoundPage = () => (
  <>
    <h1>NOT FOUND</h1>
    <p>Page not found</p>
  </>
);

export default NotFoundPage;
